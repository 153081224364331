import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StatusCard } from '@jsluna/card'
import { FlagBody, FlagWrapper } from '@jsluna/grid'
import GroupedActivitiesModal from './ActivitiesPreview/GroupedActivitiesModal'

const GroupedItemHolder = ({ className, item, history }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        minHeight: '3.5rem',
        allignItems: 'center'
      }}
    >
      <a
        role="presentation"
        style={{ cursor: 'pointer' }}
        onClick={() => setIsModalOpen(true)}
      >
        <StatusCard
          className={className}
          status={item.isUrgent ? 'danger' : 'none'}
        >
          <FlagWrapper>
            <FlagBody>
              <div
                className="ln-u-text-align-left"
                style={{
                  paddingLeft: '0.25rem',
                  paddingTop: '0.2rem', // Reduce top padding
                  paddingBottom: '0.2rem', // Reduce bottom padding
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                <b
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3, // Ensure truncation consistency
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <span className="c-grouped-item__count">
                    {item.items.length}
                  </span>
                  {item.title}
                </b>
              </div>
            </FlagBody>
          </FlagWrapper>
        </StatusCard>
      </a>
      <GroupedActivitiesModal
        group={item}
        isOpen={isModalOpen}
        activities={item.items}
        onClose={() => setIsModalOpen(false)}
        history={history}
        style={{
          position: 'relative',
          zIndex: 2,
          display: 'flex',
          minHieght: '4.5rem'
        }}
      />
    </div>
  )
}

GroupedItemHolder.propTypes = {
  className: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    duration: PropTypes.number,
    position: PropTypes.number,
    title: PropTypes.string,
    dueDate: PropTypes.string,
    spaceAbove: PropTypes.number,
    attributes: PropTypes.array,
    isCompleted: PropTypes.bool,
    isMissed: PropTypes.bool,
    isPast: PropTypes.bool,
    isUrgent: PropTypes.bool,
    items: PropTypes.array
  }).isRequired,
  history: PropTypes.object.isRequired
}

export default GroupedItemHolder
