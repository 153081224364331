import React from 'react'
import { v1 } from 'uuid'
import { StatusCard } from '@jsluna/card'
import ItemHolder from './ItemHolder'

const createItemHolders = (day, history, visibleItemsLength, isPeriodView) => {
  const elements = []
  for (let i = 0; i < visibleItemsLength; i += 1) {
    const item = day.items.find(x => x.position === i && x.shouldStartOnThisDay)
    const element = item ? (
      <ItemHolder
        history={history}
        key={v1()}
        item={item}
        isPeriodView={isPeriodView}
      />
    ) : (
      <StatusCard
        key={v1()}
        className="c-activity-item standard u-invisible"
        style={{
          minHeight: '4.5rem',
          visibility: 'hidden',
          marginBottom: '1.5rem'
        }}
      >
        Default view
      </StatusCard>
    )
    elements.push(element)
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: '0.8',
        alignItem: 'stretch'
      }}
    >
      {elements}
    </div>
  )
}

export default createItemHolders
